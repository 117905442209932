import React from 'react'
import Layout from 'layouts/fr'
import styled from 'styled-components'
import design from 'design'
import {Title, Text, Row, Div, Box, Button, Input} from 'components'
import {TryOrSchedule} from "containers/fr";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import IslandFinance from "images/PaperworkPassagebig8bitcopy.png";

export default () => {
  return (
    <Layout>
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={IslandFinance} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[4,8]} fontSize={[6,8]}>Faites-vous payer rapidement. A chaque fois.</Title>
          <Text mb={[4,6]} fontSize={[3,6]}>Spécialement conçu pour les entreprises solo, le système de paiement Stairlin est intuitif, fiable et simple. Nous faisons tout le travail pour vous afin que vous puissiez commencer à accepter des paiements en un clic de souris.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <Div display={['block','flex']}>
              <Input fontSize={[4,6]} p={[3,4]} placeholder={`Votre email`} /><Button bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primaryDark'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Démarrer maintenant</Button>
            </Div>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Installation simple • Pas de frais initiaux • Pas de carte de crédit requise • Pas de frais de résiliation</Text>
          </Div>
        </Div>
      </Box>

      <Div display={'flex'} mb={[5,7]}>
        <Title mr={7} fontSize={[5,8]} fontWeight={700}>Billing</Title>
        <Title mr={7} fontSize={[5,8]} fontWeight={400}>Accounting</Title>
      </Div>

      <Div display={['block','flex']}>
        <Div flex={1}>
          <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Prepare</Title>
            <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Release</Title>
            <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Supervise</Title>
            <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Credit</Title>
          </Div>
          <Div mr={[0,6]} textAlign={['center','left']}>
            <Title mb={[4,6]} fontSize={[6,7]}>Streamline the way you create, send, and track your invoices</Title>
            <Text mb={[4,6]} fontSize={[3,4]}>Automatic invoice generation from your reservations.</Text>
          </Div>
        </Div>
        <Div flex={1}>
          <Box height={640} m={0} p={[4,6]}>Component</Box>
        </Div>
      </Div>

      <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
        <Div mb={6} mr={[0,6]} flex={1}>
          <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Adaptive workflow</Title>
          <Text fontSize={[2,4]}>Automate invoice generation with workflows. Define whether you bill per reservation, on a monthly basis, …</Text>
        </Div>
        <Div mb={6} mr={[0,6]} flex={1}>
          <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Adaptive workflow</Title>
          <Text fontSize={[2,4]}>Automate invoice generation with workflows. Define whether you bill per reservation, on a monthly basis, …</Text>
        </Div>
        <Div mb={6} mr={[0,6]} flex={1}>
          <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Adaptive workflow</Title>
          <Text fontSize={[2,4]}>Automate invoice generation with workflows. Define whether you bill per reservation, on a monthly basis, …</Text>
        </Div>
      </Div>

      <Div p={[6,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
        <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
          <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Prepare</Title>
          <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Release</Title>
          <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Supervise</Title>
          <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Credit</Title>
        </Div>
        <Title mb={[4,5]} fontSize={[6,7]}>Build trust and look like a pro</Title>
        <Text mb={[]} fontSize={[3,4]}>Effortlessly send professional invoices across multiple channels</Text>
        <Div display={['block','flex']} mt={[6,110]}>
          <Div flex={1} mb={6}>
            <Title mb={[4,6]} fontSize={[3,6]}>Instant notifications</Title>
            <Box bg={'white'} height={640} m={0} mx={[0,8]} p={[4,6]}>Component</Box>
          </Div>
          <Div flex={1}>
            <Title mb={[4,6]} fontSize={[3,6]}>Beautifully designed invoices</Title>
            <Box bg={'white'} height={640} m={0} p={[4,6]}>Component</Box>
          </Div>
        </Div>
      </Div>

      <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
        <Div mb={6} mr={[0,6]} flex={1}>
          <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Multi-channel notifications</Title>
          <Text fontSize={[2,4]}>Send invoice across multiple channels, all in one place. Support includes email, SMS, and WhatsApp.</Text>
        </Div>
        <Div mb={6} mr={[0,6]} flex={1}>
          <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Multi-channel notifications</Title>
          <Text fontSize={[2,4]}>Send invoice across multiple channels, all in one place. Support includes email, SMS, and WhatsApp.</Text>
        </Div>
        <Div mb={6} mr={[0,6]} flex={1}>
          <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Multi-channel notifications</Title>
          <Text fontSize={[2,4]}>Send invoice across multiple channels, all in one place. Support includes email, SMS, and WhatsApp.</Text>
        </Div>
      </Div>

      <Div mt={4,8}>
        <Div display={['block','flex']} flexDirection={'row-reverse'}>
          <Div flex={1}>
            <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title ml={6} mb={4} fontSize={[3,4]} fontWeight={400}>Prepare</Title>
              <Title ml={6} mb={4} fontSize={[3,4]} fontWeight={400}>Release</Title>
              <Title ml={6} mb={4} fontSize={[3,4]} fontWeight={700}>Supervise</Title>
              <Title ml={6} mb={4} fontSize={[3,4]} fontWeight={400}>Credit</Title>
            </Div>
            <Div ml={[0,6]} textAlign={['center','right']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Never miss a payment</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Improve efficiency and stop chasing down payments with invoice tracking.</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box height={640} m={0} p={[4,6]}>Component</Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Clear and transparent tracking</Title>
            <Text fontSize={[2,4]}>Check the status of your invoices and payments from anywhere. Instantly know which invoices are paid or past due.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Clear and transparent tracking</Title>
            <Text fontSize={[2,4]}>Check the status of your invoices and payments from anywhere. Instantly know which invoices are paid or past due.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Clear and transparent tracking</Title>
            <Text fontSize={[2,4]}>Check the status of your invoices and payments from anywhere. Instantly know which invoices are paid or past due.</Text>
          </Div>
        </Div>
      </Div>

      <Div mt={[6,8]}>
        <Div display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Prepare</Title>
              <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Release</Title>
              <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Supervise</Title>
              <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Credit</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Untangle the credit snag</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Apply credits, create a credit note, apply excess payment, remove payment, record a refund, write off, or void an invoice.</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box height={640} m={0} p={[4,6]}>Component</Box>
          </Div>
        </Div>
      </Div>

      <Div mt={[4,8]} mx={[-36,-48]} color={design.color.white} bg={design.color.primary} p={[6,6]}>
        <Div display={'flex'} justifyContent={['flex-end']} mb={[5,7]}>
          <Title ml={7} fontSize={[5,8]} fontWeight={400}>Billing</Title>
          <Title ml={7} fontSize={[5,8]} fontWeight={700}>Accounting</Title>
        </Div>

        <Div display={['block','flex']} flexDirection={'row-reverse'}>
          <Div flex={1}>
            <Div justifyContent={['flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title ml={6} mb={4} fontSize={[3,4]} fontWeight={700}>Export</Title>
              <Title ml={6} mb={4} fontSize={[3,4]} fontWeight={400}>Analyse</Title>
            </Div>
            <Div ml={[0,6]} textAlign={['center','right']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Accuracy down to the dime, every time.</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Bid goodbye to accounting Sunday and manual errors.</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box bg={'white'} height={640} m={0} p={[4,6]}>Transactions</Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Monthly, Quarterly, or Yearly</Title>
            <Text fontSize={[2,4]}>All your payments and invoices are ready to be exported for any given accounting period.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={4} mr={[4,6]} fontSize={[2,4]}>Open standard</Title>
            <Text fontSize={[2,4]}>Exports are in CSV, which can be open on Microsoft Excel, Apple Numbrs, or Google Spreadsheet right off the bat.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={4} mr={[4,6]} fontSize={[2,4]}>No reconciliation needed</Title>
            <Text fontSize={[2,4]}>Because it is a real bummer when the invoice information on your accounting software does not match the figures on your bank statement. </Text>
          </Div>
        </Div>
      </Div>

      <Div mt={[6,8]}>
        <Div display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Export</Title>
              <Title mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Analyse</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title display={['block','none']} mb={[4,6]} fontSize={[6,7]}>Forecast with greater predictability</Title>
              <Title display={['none','block']} mb={[4,6]} fontSize={[6,7]}>Forecast with greater predictability by building accurate financial reports on top of your single source of truth.</Title>
            </Div>
          </Div>
          <Div flex={1}>
            <Box height={340} m={0} p={[4,6]}>Chart</Box>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>

    </Layout>
  )
}
